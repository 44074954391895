export default class VideoEmbed {

  constructor(root) {
    // Root elements
    this.root = root;
    this.playBtn = root.querySelector('.VideoEmbed__play');
    this.iframe = root.querySelector('iframe');
    this.player = null;

    if(!this.root || !this.playBtn || !this.iframe) {
      return;
    }

    this.init();
  }

  init() {
    // Check if youtube iframe API is present
    const YTiframeAPI = document.getElementById('YouTubeApi');

    if(!YTiframeAPI) {
      // Iframe API not loaded, inject
      const scriptTag = document.createElement('script');
      scriptTag.id = 'YouTubeApi';
      scriptTag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
    }

    // When script is ready
    window.onYouTubeIframeAPIReady = () => {
      this.player = new YT.Player(this.iframe, {
        events: {
          'onReady': () => this.playerReady()
        }
      });
    }
  }

  playerReady() {
    this.playClickListener = this.playVideo.bind(this);
    this.playBtn.addEventListener('click', this.playClickListener);
  }

  playVideo() {
    this.player.playVideo();
    this.root.classList.add('VideoEmbed--play');
  }

}
