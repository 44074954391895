const CookieConsent = {
  root: null,
  loading: false,

  init(root) {
    if (!root) {
      return;
    }

    // Elements
    this.root = root;
    this.form = document.getElementById('CookieConsentForm')
    this.submitAllBtn = document.getElementById('CookieConsentAll');
    this.submitAllSecondaryBtn = document.getElementById('CookieConsentAllSecondary');
    this.submitSaveBtn = document.getElementById('CookieConsentSave');
    this.openSettingsBtn = document.getElementById('CookieConsentSettings');

    // Event listeners
    this.submitAllListener = this.submitAll.bind(this);
    this.submitSaveListener = this.submitSave.bind(this);
    this.openSettingsListener = this.openSettings.bind(this);

    // Attach listeners
    this.submitAllBtn.addEventListener('click', this.submitAllListener);
    this.submitAllSecondaryBtn.addEventListener('click', this.submitAllListener);
    this.submitSaveBtn.addEventListener('click', this.submitSaveListener);
    this.openSettingsBtn.addEventListener('click', this.openSettingsListener);
  },

  openSettings(event) {
    event.preventDefault();
    this.root.classList.add('CookieConsent--open');
    document.body.classList.add('has-open-modal');
  },

  submitAll(event) {
    // Submit form and enable all consents
    event.preventDefault();
    if(this.loading) {
      // Previous request in progress
      return;
    }
    this.setLoading();

    let form = this.form;

    for (let i = 0; i < form.elements.length; i++) {
      if (form.elements[i].type == 'checkbox' && !form.elements[i].id.startsWith('more-info')) {
        if (form.elements[i].checked == false) {
          form.elements[i].checked = true;
        }
      }
    }

    let data = this.serializeForm(form);
    let xhr = new XMLHttpRequest();
    xhr.open('POST', form.dataset.url);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(data.concat('&acceptAll=true'));

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) console.log('Error: ' + xhr.status);
        else if (form.hasAttribute("data-refresh") && form.dataset.refresh === "true" && form.hasAttribute("data-refreshtime")) {
          this.refresh(form.dataset.refreshtime)
        } else {
          this.hide();
        }
      }
    }
  },

  submitSave(event) {
    // Submit form with selected consents
    event.preventDefault();
    if(this.loading) {
      // Previous request in progress
      return;
    }
    this.setLoading();

    let form = this.form;
    let data = this.serializeForm(form);

    let xhr = new XMLHttpRequest();
    xhr.open('POST', form.dataset.url);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(data);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) console.log('Error: ' + xhr.status);
        else if (form.hasAttribute("data-refresh") && form.dataset.refresh === "true" && form.hasAttribute("data-refreshtime")) {
          this.refresh(form.dataset.refreshtime)
        } else {
          this.hide();
        }
      }
    }
  },

  setLoading() {
    this.loading = true;
    this.root.classList.add('CookieConsent--loading');
  },

  hide() {
    this.root.classList.add('CookieConsent--submitted');
    this.loading = false;
  },

  // Serialize form helper function
  serializeForm(form) {
    // Setup our serialized data
    var serialized = [];

    // Loop through each field in the form
    for (var i = 0; i < form.elements.length; i++) {
      var field = form.elements[i];

      // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
      if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'button') continue;

      // If a multi-select, get all selections
      if (field.type === 'select-multiple') {
        for (var n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue;
          serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
        }
      }

      // Convert field data to a query string
      else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
        serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
      }
    }

    return serialized.join('&');
  },

  refresh(ms) {
    setTimeout(function () {
      location.reload();
    }, ms);
  }

};

export default CookieConsent;