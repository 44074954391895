import axios from 'axios';

import Input from './Input';
export default class Form {

  constructor(root) {
    this.root_ = root;
    this.form_ = this.root_.querySelector('.Form');
    if(!this.root_ || !this.form_) {
      return;
    }
    this.formWrapper = this.root_.querySelector('.Form__formWrapper');
    this.submitBtn_ = this.form_.querySelector('.Form__submit');
    this.submitBtnText_ = this.submitBtn_.firstChild.innerText;
    this.inputs_ = [...this.form_.querySelectorAll('.Form__input')];
    this.loading_ = false;
    this.hasBeenSubmitted_ = false;

    // Inputs
    if(this.inputs_ && this.inputs_.length) {
      this.inputs_.forEach(el => {
        new Input(el);
      });
    }

    // Listen for submit
    if(this.submitBtn_) {
      this.submitListener = this.onSubmit.bind(this);
      this.form_.addEventListener('submit', this.submitListener);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if(this.loading_ || this.hasBeenSubmitted_) {
      return;
    }
    // Start submitting form...
    this.loading_ = true;
    this.submitBtn_.classList.add('Form__submit--loading');
    this.submitBtn_.firstChild.innerText = 'Vent...';
    
    // Data to send
    let formData = new FormData(this.form_);
    // Send form    
    this.sendForm(formData);

    // Send copy to sender?
    const sendCopyToSender = formData.get('copyToSender') ? true : false;
    if(sendCopyToSender) {
      const senderEmail = formData.get('fromEmail');
      if(senderEmail) {
        setTimeout(() => {
          formData.set('toEmail', senderEmail);
          formData.set('mailToSender', true);
          this.sendForm(formData);
        }, 1000);
      }
    }
  }

  sendForm(formData) {
    // Post to contact form plugin endpoint
    axios({
      method: 'POST',
      url: '/',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: new URLSearchParams(formData),
    })
    .then((response) => {
      // Success
      this.hasBeenSubmitted_ = true;
      this.root_.classList.add('Form--submitted');
    })
    .catch((error) => {
      // Error
      console.log(error.response);
      console.log(error.response.data);
      alert('Noe gikk galt! Prøv igjen eller kontakt kontakt@hrhuset.no');
    })
    .then(() => {
      // Always
      this.loading_ = false;
      this.submitBtn_.classList.remove('Form__submit--loading');
      this.submitBtn_.firstChild.innerText = this.submitBtnText_;
    })
  }

}