export default class Input {

  constructor(root) {
    this.input_ = root;

    this.blurListener = this.checkIfInputHasContent.bind(this);
    this.input_.addEventListener('blur', this.blurListener);
  }

  checkIfInputHasContent() {
    if (this.input_.value) {
      // If input has received content add class
      this.input_.classList.add('has-content');
    } else {
      // If input no longer has content remove class
      this.input_.classList.remove('has-content');
    }
  }

}
