export default class Dropdown {

  constructor() {
    this.header = document.getElementById('TheHeader');
    this.btns = [...document.querySelectorAll('[data-toggle-dropdown]')];
    this.dropdowns = [...document.querySelectorAll('.Dropdown')];
    this.currentOpenDropdown = '';

    if(this.btns.length > 0) {
      this.btns.forEach(btnElm => {
        btnElm.addEventListener('click', () => { 
          this.onClickDropdownBtn(btnElm, btnElm.dataset.toggleDropdown);
        })
      });
    }
  }

  onClickDropdownBtn(btn, dropdown) {
    if(this.currentOpenDropdown === dropdown) {
      // Click same dropdown again (close)
      this.dropdowns.forEach(el => {
        this.closeDropdown(el);
      });
      // Reset aria label on btn
      this.btns.forEach(btnElm => {
        btnElm.setAttribute('aria-expanded', 'false');
      });
      // Reset open dropdown
      this.currentOpenDropdown = '';
      // Reset nav text color to black
      this.header.classList.remove('TheHeader--dropdown--open');
    } else {
      // Clicking new dropdown (open)
      this.dropdowns.forEach(dropdownElm => {
        if(dropdownElm.dataset.dropdown === dropdown) {
          // Open selected dropdown
          this.openDropdown(dropdownElm)
        } else {
          // Hide previous open dropdown
          this.closeDropdown(dropdownElm);
        }
      });
      // Set aria label open on current btn
      this.btns.forEach(btnElm => {
        btnElm.setAttribute('aria-expanded', 'false');
      });
      btn.setAttribute('aria-expanded', 'true');
      // Set current open dropdown
      this.currentOpenDropdown = dropdown;
      // Change nav text color to white
      this.header.classList.add('TheHeader--dropdown--open');
    }
  }

  openDropdown(dropdownElm) {
    dropdownElm.classList.add('Dropdown--open');
    const anchors = [...dropdownElm.querySelectorAll('a')];
    if(anchors.length > 0) {
      anchors.forEach(anchorElm => {
        anchorElm.setAttribute('tabindex', '0');
      });
      anchors[0].focus();
    }
  }

  closeDropdown(dropdownElm) {
    dropdownElm.classList.remove('Dropdown--open');
    const anchors = [...dropdownElm.querySelectorAll('a')];
    if(anchors.length > 0) {
      anchors.forEach(anchorElm => {
        anchorElm.setAttribute('tabindex', '-1');
      });
    }
  }

}
