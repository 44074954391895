// Import favicons
import faviconAlternate from './img/favicon.png';
import favicon from './img/favicon.svg';

// Import fonts for webpack
import UntitledSansWoff from './fonts/UntitledSans/untitled-sans-web-regular.woff';
import UntitledSansWoff2 from './fonts/UntitledSans/untitled-sans-web-regular.woff2';
import GTFlexaWoff from './fonts/GTFlexa/GT-Flexa-Standard-Light.woff';
import GTFlexaWoff2 from './fonts/GTFlexa/GT-Flexa-Standard-Light.woff2';

// Components
import CookieConsent from './components/CookieConsent';
import Form from './components/Form';
import Dropdown from './components/Dropdown';
import getScrollHandler from './components/ScrollHandler';
import VideoEmbed from './components/VideoEmbed';

//
// Application
//
const HRHuset = {
  // Options / globals
  navTrigger: null,
  body: document.body,
  header: document.getElementById('TheHeader'),
  navOpen: false,
  navTrigger: document.getElementById('NavTrigger'),
  nav: document.getElementById('TheMainNav'),
  featuredEntriesItems: [],

  init() {

    // Cookie consent
    const cookieConsent = document.getElementById('CookieConsent')
    if(cookieConsent) {
      CookieConsent.init(cookieConsent);
    }

    // Forms
    [...document.querySelectorAll('.Form__wrapper')].forEach(el => {
      new Form(el);
    });

    // VideoEmbeds
    [...document.querySelectorAll('.VideoEmbed')].forEach(el => {
      new VideoEmbed(el);
    });

    // Dropdown
    const dropdown = document.querySelector('.Dropdown');
    if(dropdown) {
      new Dropdown();
    }

    // Featured entries items
    this.featuredEntriesItems = [...document.querySelectorAll('.FeaturedEntriesItem')];
    if(this.featuredEntriesItems.length > 1 && window.matchMedia("(min-width: 768px)").matches) {
      document.fonts.ready.then(() => {
        this.calculateFeaturedEntriesTitles();
      });
    }

    this.navTriggerClickListener = this.toggleNavigation.bind(this);
    this.navTrigger.addEventListener('click', this.navTriggerClickListener);

    // Nav scroll toggle
    this.navTrigger.addEventListener('click', () => this.body.classList.toggle('has-open-nav'));

    this.scrollHandler = getScrollHandler();
    this.scrollHandler.listen('pass:50', evt => this.body.classList.toggle('has-scrolled-past-50', evt.detail.isPast), false);

    // On browser resize
    window.addEventListener('resize', () => this.onResize());
  },

  toggleNavigation() {
    this.navOpen = !this.navOpen;
    if(this.navOpen) {
      this.navTrigger.setAttribute('aria-expanded', 'true');
      this.navTrigger.setAttribute('aria-label', this.navTrigger.dataset.closeLabel);
      this.nav.classList.add('TheMainNav--open');
      const firstButton = this.nav.querySelector('button');
      if(firstButton) { firstButton.focus(); }
    } else {
      this.navTrigger.setAttribute('aria-expanded', 'false');
      this.navTrigger.setAttribute('aria-label', this.navTrigger.dataset.openLabel);
      this.nav.classList.remove('TheMainNav--open');
    }
  },

  initOnLoad() {
    // console.log('HR Huset started!');
  },

  onResize() {
    if(this.featuredEntriesItems.length > 1 && window.matchMedia("(min-width: 768px)").matches) {
      this.calculateFeaturedEntriesTitles();
    }
  },

  calculateFeaturedEntriesTitles() {
    let tallestTitle = 0;
    // Get tallest title
    this.featuredEntriesItems.forEach(el => {
      const title = el.querySelector('h3');
      // Reset old height if set 
      title.style.height = 'auto';
      // Get height
      const titleHeight = title.offsetHeight;
      if(titleHeight > tallestTitle) {
        tallestTitle = titleHeight;
      }
    });
    // Set all titles to height of tallest one
    this.featuredEntriesItems.forEach(el => {
      const title = el.querySelector('h3');
      title.style.height = tallestTitle + 'px';
    });
  }

};

document.addEventListener('DOMContentLoaded', () => HRHuset.init());
window.addEventListener('load', () => HRHuset.initOnLoad());

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
